import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./Bars.scss";
import TopBar from "./TopBar/TopBar";
// import Logo from "../../img/logo2.png";
import ToggleButton from "./SideDrawer/ToggleButton";
import SideDrawer from "./SideDrawer/SideDrawer";
import Backdrop from "./Backdrop/Backdrop";
import NavBar from "./NavBar/NavBar.js";

export default class Bars extends Component {
  state = {
    admin: false,
    sideDrawerOpen: false,
    barName: "bars",
    prevScrollPos: window.scrollY,
  };
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    const currentScrollPos = window.screenY;
    if (this.state.prevScrollPos >= currentScrollPos) {
      this.setState({
        barName: "bars",
      });
    } else if (window.scrollY < 350) {
      this.setState({
        barName: "bars",
      });
    } else {
      this.setState({
        barName: "bars small",
      });
    }
    this.setState({
      prevScrollPos: currentScrollPos,
    });
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  removeBackdrop = () => {
    this.setState({
      sideDrawerOpen: false,
    });
  };
  render() {
    return (
      <header className={this.state.barName}>
        <NavLink to="/" className="bars__logo">
          <img src="/logo.png" alt="logo" />
        </NavLink>
        <div className="bars__links">
          <Backdrop clicked={this.removeBackdrop} show={this.state.sideDrawerOpen} />
          <TopBar />
          <NavBar admin={this.state.admin} />
          {/* <ToggleButton clicked={this.drawerToggleClickHandler} /> */}
          {/* <SideDrawer
            show={this.state.sideDrawerOpen}
            clicked={this.drawerToggleClickHandler}
          /> */}
        </div>
      </header>
    );
  }
}
