import React from "react";
import "./Gardenia.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Bars from "./components/Bars/Bars";
import Home from "./containers/Home/Home";
import Footer from "./components/Footer/Footer";
import News from "./containers/News/News";
import SinglePost from "./containers/SinglePost/SinglePost.js";
// import { useSelector, useDispatch } from "react-redux";
import News2 from "./containers/News2/News2";
import NoMatch from "./containers/NoMatch/NoMatch";
import Admin from "./containers/Admin/Admin.js";
import news from "./all__news.json";
import SimplePage from "./components/SimplePage/SimplePage";
// import AdvModule from "./components/AdvModule/AdvModule";
// import { increment, decrement } from "./action/Index.js";
import baseUrl from "./config.js";
import { useEffect, useState } from "react";

export default function Gardenia() {
  // let currentDay1 = new Date().setHours(0, 0, 0, 0);
  let currentDay1 = new Date();
  // console.log("New date", currentDay1);
  const publishedNews = news.filter((item, index) => {
    // let currentDay2 = new Date(news[index].time2).setHours(0, 0, 0, 0);
    let currentDay2 = new Date(news[index].time2);
    // console.log("New date 2", currentDay2);
    return item.published && currentDay1.valueOf() >= currentDay2.valueOf();
  });
  const publishedNewsPromo = publishedNews.filter((item) => {
    return item.promoExcluded !== true;
  });
  // counter = useSelector((state) => state.counter);
  // dispatch = useDispatch();

  const [news2, setNews] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${baseUrl}/api/all/news`);
        const data = await res.json();
        setNews(data);
        console.log("Data", data.news);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  console.log("News2", news2);
  return (
    <>
      {news && (
        <>
          <Router basename={process.env.PUBLIC_URL}>
            <div className="app">
              {/* <AdvModule classes={"adClass_Footer"} size={"s/f"} /> */}
              <Bars />
              <div className="app-wrapper">
                <div className="app-body">
                  <Switch>
                    <Route path="/" exact>
                      <Home news={publishedNewsPromo} />
                      {/* <Home news={news2} /> */}
                    </Route>
                    <Route path="/:id/:id/" exact>
                      <SinglePost data={publishedNews} dataPromo={publishedNewsPromo} />
                    </Route>
                    {/* <Route path="/:slug/" exact>
                      <News data={publishedNews} />
                    </Route> */}
                    {/* <Route path="/vesti/:id" exact>
                <News2 data={publishedNews} />
              </Route>
             
              <Route path="/najnovije-vesti" exact component={News2}>
                <News2 data={publishedNews} />
              </Route>
              <Route path="/subvencije" exact component={News2} />
              <Route path="/zivot-na-selu/:id" exact>
                <News2 data={publishedNews} />
              </Route>
              <Route path="/zivot-na-selu/:id/:id">
                <SinglePost
                  data={publishedNews}
                  dataPromo={publishedNewsPromo}
                />
              </Route>
              <Route path="/zivot-na-selu" exact component={News} />
              <Route path="/o-nama" exact component={SimplePage} />
              <Route path="/marketing" exact component={SimplePage} />
              <Route path="/pravila-koriscenja" exact component={SimplePage} />
              <Route
                path="/politika-privatnosti"
                exact
                component={SimplePage}
              /> */}

                    {/* <Route path="/admin" exact>
                <Admin data={news} />
              </Route> */}
                    <Route path="*" component={NoMatch} />
                  </Switch>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </Router>
        </>
      )}
    </>
  );
}
