import React, { Component } from "react";
import Buttons from "../../UI/Button/Buttons";
import "./NoMatch.scss";
import data from "../../all__news.json";
import ArticalBox2 from "../../components/ArticalBox/ArticalBox";
import { withRouter } from "react-router-dom";

class NoMatch extends Component {
  state = {
    categoryNews: data,
  };
  componentDidMount = () => {
    const publishedNews = [];
    data.forEach((x) => {
      if (x.published) {
        publishedNews.push(x);
      }
    });
    this.setState({
      allNews: publishedNews,
    });
    this.changeStateOnMount(publishedNews);
  };
  changeStateOnMount = (publishedNews) => {};
  setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox2 key={index} n={news} classes={classBox} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };
  setClass = () => {
    if (window.innerWidth < 500) {
      return "boxWrapper box2 news3";
    } else {
      return "boxWrapper mainBox";
    }
  };
  changePage = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="noMatch">
        <div className="noMatch-top">
          <img src={"/img/noMatch.png"} alt="" />
          <div>
            <span>UUUPS!</span>
            <h2>Greška 404: Stranica nije pronađena</h2>
            <p>
              Nešto se dogodilo! Trenutno ne možemo da pronađemo stranicu koju trazite ili stranica ne postoji. <br /> Molimo vas da osvežite stranicu ili pokušate da odete na neku drugu.
            </p>
            <Buttons children="Nazad na početnu" clicked={this.changePage} />
          </div>
        </div>

        <section className="home__box__11">
          <h1>Najnovije</h1>
          {this.setNewsBoxes([1, 7], this.state.allNews, "home__box__2-layout", this.setClass())}
        </section>
      </div>
    );
  }
}

export default withRouter(NoMatch);
