import React from "react";
import "./Gallery.scss";

export default function Gallery() {
  return (
    <div className="gallery">
      <h3>Galerija</h3>
      <div className="gallery-wrapper">
        <div className="img1">
          <img src="https://res.cloudinary.com/dvi29tcsc/image/upload/v1727539769/agroweb/jesen_djubrenje_2_birkhc.jpg" alt="" />
        </div>
        <div className="img2">
          <img src="https://res.cloudinary.com/dvi29tcsc/image/upload/v1678793862/agroweb/basil_e2s3zv.jpg" alt="" />
        </div>
        <div className="img3">
          <img src="https://res.cloudinary.com/dvi29tcsc/image/upload/v1725612178/agroweb/heljda_jyuekz.jpg" alt="" />
        </div>
        <div className="img4">
          <img src="https://res.cloudinary.com/dvi29tcsc/image/upload/v1725876516/agroweb/Brennnessel_1_cqixmr.jpg" alt="" />
        </div>
        <div className="img5">
          <img src="https://res.cloudinary.com/dvi29tcsc/image/upload/v1726493114/agroweb/foodiesfeed.com_fresh-organic-vegetables_cslfw9.jpg" alt="" />
        </div>
      </div>
    </div>
  );
}
