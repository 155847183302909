import React, { Component } from "react";
import "./Home.scss";
import Backdrop from "../../components/Bars/Backdrop/Backdrop";
import { newsShedule, newsShedule2, options } from "../../shared/shared";
// import AdvModule from "../../components/AdvModule/AdvModule";
import ArticalBox from "../../components/ArticalBox/ArticalBox";
// import SocialLinks from "../../components/HeadHelmet/HeadHelmet";
// import Widget from "../../UI/Widget/Widget";
import Gallery from "../../components/Gallery/Gallery";
export default class Home extends Component {
  state = {
    showPopup: false,
    showScroll: "toTop",
    allNews: this.props.news,
    actualNews: null,
    // topNews: this.props.news,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.changeStateOnMount(newsShedule, this.props.news);
  }
  changeStateOnMount = (newsIds, publishedNews) => {
    const actualNews1 = [];
    const topNews = [];
    const promoNews = [];
    publishedNews.map((x) => {
      if (newsIds[0].home.includes(parseInt(x.news_id))) {
        actualNews1.push(x);
      } else {
        promoNews.push(x);
      }
    });
    publishedNews.map((x) => {
      if (newsIds[1].home_topNews_1.includes(parseInt(x.news_id))) {
        topNews.push(x);
      }
    });
    publishedNews.map((x) => {
      if (newsIds[1].home_topNews_1.includes(parseInt(x.news_id))) {
        topNews.push(x);
      }
    });
    this.setState({
      actualNews: actualNews1,
      topNews: topNews,
      allNews: promoNews,
    });
    // console.log("Top news", topNews);
  };
  displayClassName = (classes) => {
    if (window.innerWidth > 500) {
      return "boxWrapper box2";
    } else {
      return "boxWrapper mainBox bigBox";
    }
  };
  setNewsBoxes = (n, newsState, classDiv, classBox) => {
    return newsState ? (
      <div className={classDiv}>
        {newsState.slice(n[0], n[1]).map((news, index) => {
          return <ArticalBox key={index} n={news} classes={classBox} />;
        })}
      </div>
    ) : (
      <div>Loading...</div>
    );
  };

  urlTitle = (title) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let url_title = title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      })
      .join("")
      .split(" ")
      .join("-")
      .replace(regex, "-");
    return url_title;
  };
  render() {
    console.log("Home props", this.props);
    const boxes = [
      this.setNewsBoxes([1, 3], this.state.allNews, "home__rightBox", "boxWrapper sideBox"),
      this.setNewsBoxes(
        [4, 7],
        this.state.allNews,
        "home__leftBox",
        this.displayClassName()
        // "boxWrapper box2"
      ),
      this.setNewsBoxes([0, 3], this.state.actualNews, "box3__left", "boxWrapper box3"),
      this.setNewsBoxes([8, 10], this.state.allNews, "home__box__4-layout", "boxWrapper mainBox bigBox"),
      this.setNewsBoxes([10, 14], this.state.allNews, "home__box__4-layout", "boxWrapper box4"),
    ];
    return (
      <div className="home__1">
        {/* <SocialLinks title={""} post={options[0].social} /> */}
        {/* <AdvModule classes={"adClass_Footer"} size={"s/f"} /> */}
        {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} /> */}
        <section className="homeTopNews">{this.state.topNews && this.state.topNews.length > 1 ? <ArticalBox n={this.state.allNews[0]} classes="boxWrapper topNews" /> : null}</section>
        <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={this.state.allNews[1]} classes="boxWrapper sideBox" />
          </div>
        </section>
        <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={this.state.allNews[2]} classes="boxWrapper sideBox right" />
          </div>
        </section>
        <section className="home__box__2">
          <Gallery />
        </section>
        <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={this.state.allNews[3]} classes="boxWrapper sideBox" />
          </div>
        </section>
        {/* <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={this.state.allNews[0]} classes="boxWrapper sideBox" />
          </div>
        </section> */}
        <section className="homeTopNews">{this.state.topNews && this.state.topNews.length > 1 ? <ArticalBox n={this.state.topNews[1]} classes="boxWrapper topNews" /> : null}</section>

        <section className="home__box__1">
          <div className="home__box">
            <ArticalBox n={this.state.allNews[4]} classes="boxWrapper sideBox" />
          </div>
        </section>
        {/* <section className="home__box__3">
          <Widget />
        </section> */}
        {/* <h2>{"TEST"}</h2> */}
        {/* <AdvModule classes={"adClass small"} size={"s"} />
        <section className="home__box__2">
          {boxes[1]}
          <div className="home__rightBox">
            <AdvModule classes={"adClass large"} size={"xl"} />
            <AdvModule classes={"adClass small"} size={"s"} />
          </div>
        </section> */}
        {/* <section className="topNews">{this.state.topNews && <ArticalBox n={this.state.topNews[0]} classes="boxWrapper topNews" />}</section> */}
        {/* <section className="home__box__3">
          <div className="home_3_title">
            <span>Aktuelno</span>
          </div>
          <div className="box3">{boxes[2]}</div>
        </section> */}
        {/* <AdvModule classes={"adClass__XXL"} size={"xxl"} />
        <section className="home__box__4">
          <span>Pročitajte još...</span>
          {boxes[3]}
          {boxes[4]}
        </section>
        <AdvModule classes="adClass__XXL" size={"xxl"} /> */}
        {/* <section className="topNews">
          {this.state.topNews && this.state.topNews.length > 1 ? (
            <ArticalBox2
              n={this.state.topNews[1]}
              classes="boxWrapper topNews"
            />
          ) : null}
        </section> */}
        <Backdrop clicked={this.removeCommentBox} show={this.state.showPopup} />
      </div>
    );
  }
}
